import config from "common/config";
import { client } from "common/repositories";
import toApiError from "common/repositories/to-api-error";
import { decamelizeKeys, decamelize } from "humps";
import ky from "ky";

type MutationMethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export function MutationFetchFunction(
  url: string,
  method: MutationMethodType,
  body?: any,
  isJSON: boolean = true
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const newBody = body && isJSON ? decamelizeKeys(body) : body;

    try {
      resolve(
        await client(url, {
          method,
          ...(body && isJSON
            ? {
                json: newBody,
              }
            : { body: newBody }),
        }).json()
      );
    } catch (e: any) {
      reject(await toApiError(e));
    }
  });
}

interface DownloadFileProps {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  accessToken: string;
  fileType: "xlsx" | "pdf" | "zip";
  title: string;
}

export const DownloadFile = async (props: DownloadFileProps) => {
  const { url, method, accessToken, fileType, title } = props;

  await ky(`${config.apiEndpoint}/api/user${url}`, {
    method,
    timeout: 90 * 1000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(async (response) => {
    await response.blob().then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.${fileType}`);
      document.body.appendChild(link);
      link.click();
    });
  });
};

export function getFormData(object, arrayKeys: string[]) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (arrayKeys.includes(key)) {
      return object[key].map((item, index) =>
        formData.append(`${decamelize(key)}[${index}]`, item)
      );
    }
    return formData.append(decamelize(key), object[key]);
  });
  return formData;
}
